module.exports = new Promise(resolve => {
      const remoteUrlWithVersion = window.cmnComponentUrl + '/remoteEntryCommonUI.js?v=' + window.VERSION
      const script = document.createElement('script')
      script.src = remoteUrlWithVersion
      script.onload = () => {
        // the injected script has loaded and is available on window
        // we can now resolve this Promise
        const proxy = {
          get: (request) => window.common.get(request),
          init: (arg) => {
            try {
              return window.common.init(arg)
            } catch(e) {
              console.log('remote container already initialized for common mf')
            }
          }
        }
        resolve(proxy)
      }
      // inject this script with the src set to the versioned remoteEntry.js
      document.head.appendChild(script);
    });